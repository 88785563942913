import Cookies from 'js-cookie'
import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  currentUser: null,
  validationMessage: null,
  profileData: null,
  loading: false
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.currentUser = action.payload
    },
    logoutUser: (state, action) => {
      state.currentUser = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const {
  loginUser,
  logoutUser,
  setLoading
} = userSlice.actions;

export default userSlice.reducer
