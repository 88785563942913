import React from 'react';
import {Outlet, Navigate} from 'react-router-dom';

const PrivateRoutes = () => {
  return (
    sessionStorage.getItem("session") ? <Outlet/> :
      <Navigate to={'/login'}/>
  );
};

export default PrivateRoutes;
