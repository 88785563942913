import React, {Suspense} from 'react';
import {HashRouter, Route, Routes} from 'react-router-dom';
import './scss/style.scss';
import PrivateRoutes from "./PrivateRoutes";
import {AuthProvider} from "./pages/AuthProvider";


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./pages/login/Login'));
const Page404 = React.lazy(() => import('./pages/page404/Page404'));
const Page500 = React.lazy(() => import('./pages/page500/Page500'));

const App = () => {
  return (
    <AuthProvider>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/" element={<Login/>}/>
            <Route element={<PrivateRoutes/>}>
            <Route path="/*" element={<DefaultLayout/>}/>
            </Route>
            <Route path="/404" element={<Page404/>}/>
            <Route path="/500" element={<Page500/>}/>
            <Route path="/login" element={<Login/>}/>
          </Routes>
        </Suspense>
      </HashRouter>
    </AuthProvider>
  );
};

export default App;
